import { inject, ref } from 'vue';
import { invokeKey } from '@drapejs/core';
import * as sales from '@/commands/sales';
import useContext from '@/composables/useContext';
export default function useMarketingSubscriber() {
  const invoke = inject(invokeKey);

  const { route } = useContext();

  const requestPending = ref(false);

  async function subscribe({ email }) {
    try {
      requestPending.value = true;
      const requestArgs = {
        url: `${route.protocol}//${route.host}${route.pathname}`,
        email,
      };

      const response = await invoke?.(sales.commands.subscribeToVoyadoMarketingPromotions, requestArgs);

      return response;
    } finally {
      requestPending.value = false;
    }
  }

  return {
    requestPending,
    subscribe,
  };
}
